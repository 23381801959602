import { useState } from "react";
import { Box, Flex, Text, Button, Icon, Link } from "@chakra-ui/react";
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import { useBlackWhiteBg, useBlueButton, useGradientColor, useLinkColor, usePillButtonBg, usePillButtonHighLight, useTextColor } from "../../../theme/globalColorTheme";
import { Tooltip } from "recharts";
import { AiOutlineCopy, AiOutlineLink } from "react-icons/ai";

const ShareAccessControlCard = ({ data, EditAccessDetails, updateAccessCode, btnLoading, createSpaceAccess, editSpaceAccess, handleUpdateDocumentSpaceAccessCode, projectData }) => {
  const navigate = useNavigate()
  const pathname = window.location.pathname;
  const subroute = pathname.split('/')[1];
  const [showTooltip, setShowTooltip] = useState(false);
  const currentHost = window.location.hostname.includes('localhost') ? 'https://lingolet.ai/' : window.location.protocol + "//" + window.location.hostname + "/";

  return (
    <Flex
      borderRadius="md"
      overflow="hidden"
      borderWidth={"0.05px"}
      borderLeftWidth={'10px'}
      alignItems="center"
      flexDir="column"
      borderLeftColor={data?.control_access_disabled ? "red.300" : "green.300"}
      borderColor={data?.control_access_disabled ? "red.200" : "green.300"}
    >
      <Flex align="center" justify="space-between" pl={4} p={2} w="full">
        <Flex w="20%" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          <Text fontSize="md" fontWeight="bold">
            {data?.control_user_email}
          </Text>
        </Flex>
        <Flex w="20%">
          {
            !data?.control_access_disabled ? <>
              {data?.control_full_access ?
                <Flex w="fit-content" bg={'green.200'} p="2" rounded="lg">
                  <Text fontSize="sm" color="black">
                    Full access
                  </Text>
                </Flex>
                :
                <Flex w={'200px'} direction={'row'}>
                  <Text fontSize="sm" color="black">Limited Access from {" "}
                    {moment(data?.control_access_start).format("MM/DD/YYYY")}
                    {" "} to {" "}
                    {moment(data?.control_access_end).format("MM/DD/YYYY")}</Text>
                </Flex>
              }
            </> : <Flex w="fit-content" bg={'red.200'} p="2" rounded="lg">
              <Text fontSize="sm" color="black">
                Disabled
              </Text>
            </Flex>
          }

        </Flex>
        <Flex alignItems="center">
          <Text fontSize="md" fontWeight="medium">
            {data?.control_access_code}
          </Text>
          <Button size="sm" variant="outline" isLoading={btnLoading?.accessCodeUpdate === data?.control_uuid} onClick={() => updateAccessCode(data)}>
            Change
          </Button>
        </Flex>
        <Flex gap={2}>

          <Button size="sm" variant="outline" onClick={() => {
            navigate(`/${subroute}/project/content/list`, {
              state: {
                access_control_uuid: data?.control_uuid,
                projectData
              }
            })
          }}>
            View Files
          </Button>
          <Button size="sm" variant="outline" onClick={() => EditAccessDetails(data)}>
            Edit Access
          </Button>
        </Flex>
      </Flex>


      <Flex w="full" p="2" borderTopWidth={'0.5px'} borderTopColor={useGradientColor}>
        {
          data?.document_space_uuid &&
          <Flex direction={"column"} bg={useBlackWhiteBg} w={'60%'} rounded="md" p="2" >
            <Flex mt={1} mb={1} justifyContent="space-between">
              <Flex >
                <Text mt={2} mr={2} fontSize={"lg"} color={useLinkColor}>
                  {data?.document_space_uuid ? currentHost + `translation/documents?space=${data?.document_space_name}` : "No Url"}
                </Text>
              </Flex>
              <Flex h="full">
                <Box
                  w={"35px"}
                  ml={1}
                  _hover={{
                    bg: usePillButtonHighLight,
                    cursor: "pointer",
                    color: 'black'
                  }}
                  color={useTextColor}
                  bg={usePillButtonBg}
                  pr={2}
                  rounded={"md"}
                  mr={2}
                >
                  <Icon
                    onClick={() => navigator.clipboard.writeText(currentHost + `translation/documents?space=${data?.document_space_name}`)}
                    ml={2}
                    mt={1}
                    h={5}
                    _hover={{ cursor: "pointer" }}
                    w={5}
                    as={AiOutlineCopy}
                  />
                </Box>

                <Link
                  p={1}
                  w={'35px'}
                  _hover={{
                    bg: usePillButtonHighLight,
                    cursor: "pointer",
                    color: 'black'
                  }}
                  color={useTextColor}
                  bg={usePillButtonBg}
                  rounded={"md"}
                  mr={2}
                  align={'center'}
                  href={currentHost + `translation/documents?space=${data?.document_space_name}`}
                  target="_blank"
                >
                  <Icon
                    ml={2}
                    mt={1}
                    h={5}
                    _hover={{ cursor: "pointer" }}
                    w={5}
                    as={AiOutlineLink}
                  />
                </Link>
              </Flex>
            </Flex>
            {
              data?.document_space_access_id && 
              <Flex mt={1} mb={1} justifyContent="space-between" borderTopWidth={'0.5px'} borderTopColor={useGradientColor} pt="2">
              <Flex >
                <Text mt={2} mr={2} fontSize={"lg"} color={useLinkColor}>
                  {data?.document_space_uuid ? currentHost + `translation/documents?space=${data?.document_space_name}&code=${data?.document_space_access_id}` : "No Url"}
                </Text>
              </Flex>
              <Flex h="full">
                <Box
                  w={"35px"}
                  ml={1}
                  _hover={{
                    bg: usePillButtonHighLight,
                    cursor: "pointer",
                    color: 'black'
                  }}
                  color={useTextColor}
                  bg={usePillButtonBg}
                  pr={2}
                  rounded={"md"}
                  mr={2}
                >
                  <Icon
                    onClick={() => navigator.clipboard.writeText(currentHost + `translation/documents?space=${data?.document_space_name}&code=${data?.document_space_access_id}`)}
                    ml={2}
                    mt={1}
                    h={5}
                    _hover={{ cursor: "pointer" }}
                    w={5}
                    as={AiOutlineCopy}
                  />
                </Box>

                <Link
                  p={1}
                  w={'35px'}
                  _hover={{
                    bg: usePillButtonHighLight,
                    cursor: "pointer",
                    color: 'black'
                  }}
                  color={useTextColor}
                  bg={usePillButtonBg}
                  rounded={"md"}
                  mr={2}
                  align={'center'}
                  href={currentHost + `translation/documents?space=${data?.document_space_name}&code=${data?.document_space_access_id}`}
                  target="_blank"
                >
                  <Icon
                    ml={2}
                    mt={1}
                    h={5}
                    _hover={{ cursor: "pointer" }}
                    w={5}
                    as={AiOutlineLink}
                  />
                </Link>
              </Flex>
            </Flex>
            }
          </Flex>
        }
        {data?.document_space_uuid && <Flex ml="auto" mr='2'>
          {data?.document_space_access_id ?
            <Flex mr="2" ml="2">
              <Text p="4px" w='85px' color={"gray.600"} borderWidth={'0.05px'} fontWeight={"bold"} textAlign="center" bg={"green.100"} borderColor={'green.200'} fontSize={"sm"} borderLeftRadius={'10px !important'} mr='-3' h="fit-content">
                {data?.document_space_access_id}
              </Text>
              <Button
                isLoading={btnLoading?.updateDocSpaceAccessCode}
                onClick={() => handleUpdateDocumentSpaceAccessCode(data)}
                size={"sm"}
                bg={useBlueButton}
                color={useTextColor}
                colorScheme="gray"
                _hover={{ bg: useBlueButton, color: useTextColor }}
                borderRadius={'10px'}
              >
                <Text as="span" ml="1"> Change</Text>
              </Button>
            </Flex>

            : <Flex>
              <Button size="sm" isLoading={btnLoading?.updateDocSpaceAccessCode} onClick={() => handleUpdateDocumentSpaceAccessCode(data)}>Generate Access code</Button>
            </Flex>
          }
        </Flex>}
        <Flex ml={data?.document_space_uuid ? 0 : "auto"}>
          {!data?.document_space_uuid ? <Button size="sm" variant="outline" colorScheme="green" onClick={() => createSpaceAccess(data)}>
            Create Space Access
          </Button> :
            <Button size="sm" variant="outline" colorScheme="green" onClick={() => editSpaceAccess(data)} >
              Edit Space Access
            </Button>
          }
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ShareAccessControlCard;
