import { Alert, AlertDescription, 
    Text,
    AlertIcon, AlertTitle, Badge, Box, Button, Card, Flex, Icon, Input, InputGroup, InputLeftElement, Spacer, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react'
import { useState } from 'react';
import { SearchBar } from '../../../components/navbar/searchBar/SearchBar';
import { FaFilePdf } from 'react-icons/fa6';
import { FaFileAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { accessControlDocContent } from '../../../services/accountServices';
import { useEffect } from 'react';
import Pagination from '../../../components/pagination/Pagination';
import { HiOutlineSearch } from 'react-icons/hi';
import moment from "moment";

const ContentList = () => {
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const controlUuid = location?.state?.access_control_uuid;
    const projectData = location?.state?.projectData

    const [pageId, setPageId] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(0)
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState([])
    const [searchValue, setSearchValue] = useState(null)
    const navigate = useNavigate()

    const path = window.location.pathname
    const subroute = path.split("/")[1]

    useEffect(() => {
        handleGetContents()
    }, [controlUuid])

    useEffect(() => {
        setPageId(1)
    }, [recordsPerPage])

    function handleGetContents() {
        setLoading(true)
        const paramObj = {
            "page_id": pageId,
            "records_per_page": recordsPerPage,
            "doc_control_uuid": controlUuid
        }

        accessControlDocContent(paramObj).then(resp => {
            if (resp?.data[0]?.data?.data) {
                setData(resp?.data[0]?.data?.data)
                setFilterData(resp?.data[0]?.data?.data)
                setTotalPages(resp?.data[0]?.data?.total_pages)
            } else {
                setData([])
                setFilterData([])
                setTotalPages(0)
            }
        }).catch(e => {
            setData([])
            setFilterData([])
            setTotalPages(0)
        }).finally(() => {
            setLoading(false)
        })
    }

    function handleFilterData(searchValue) {
        const newFilteredData = data?.filter(item => item?.content_title?.indexOf(searchValue) > -1)
        setFilterData(newFilteredData)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchValue) {
                handleFilterData(searchValue)
            }
        }, 200)
        return () => {
            clearTimeout(timer)
        }
    }, [searchValue])



    return (
        <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
            <Flex
                p="3"
                gap={1}
                alignItems="center"
                borderBottomWidth="1px"
                justifyContent={"space-between"}
            >
                <Spacer />
                <Button
                    colorScheme="blue" size="sm"
                    onClick={() => {
                        navigate(`/${subroute}/projects`)
                    }}>
                    Back to Projects list
                </Button>
                <Button
                    colorScheme="blue" size="sm"
                    onClick={() => navigate(`/${subroute}/project/share-control`, {
                        state: { projectData }
                    })}>
                    Back to shared Access
                </Button>
                <Button
                    colorScheme="blue" size="sm"
                    onClick={() => {
                        handleGetContents();
                    }}>
                    Refresh
                </Button>
            </Flex>
            <Card direction="column" p="0" mt="5">
                <Box w={"100%"} h="calc(100vh - 200px)">
                    <Card px="0" h="full">
                        {data && data?.length > 0 &&
                            <Flex alignItems="center" p="2">
                                <InputGroup w="30%">
                                    <InputLeftElement pointerEvents='none'>
                                        <Icon as={HiOutlineSearch} color="gray.400" />
                                    </InputLeftElement>
                                    <Input type='search' placeholder='Search..' borderRadius="lg" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                </InputGroup>
                            </Flex>
                        }

                        {loading ? (
                            <Flex alignItems="center" justifyContent="center" mt="10" h="50vh">
                                <Spinner size="xl" />
                            </Flex>
                        ) : filterData && filterData.length > 0 ? (
                            <Box overflow="hidden" p={4} position="relative" h="full">
                                <Box
                                    overflowY="auto"
                                    maxH="calc(100% - 50px)"
                                    className="custom-scrollbar"
                                >
                                    <Table variant="simple">
                                        <Thead position="sticky" top="0" bg="white" zIndex="1">
                                            <Tr borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    TITLE
                                                </Th>
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                   {/* VECTORIZATION */}
                                                    Date Added
                                                </Th>
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    OVERWRITTEN
                                                </Th>
                                                {/* <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    STORED
                                                </Th> */}
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    ACTION
                                                </Th>
                                                <Th borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                    TRANSLATED CONTENT
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {filterData.map((item, index) => (
                                                <Tr
                                                    key={item?.content_uuid}
                                                    borderBottomWidth="1px"
                                                    borderBottomColor="#d7d7d7"
                                                >
                                                    <Td display="flex" alignItems="center">
                                                        <Icon
                                                            as={
                                                                item.type === "application/pdf"
                                                                    ? FaFilePdf
                                                                    : FaFileAlt
                                                            }
                                                            color={
                                                                item.type === "application/pdf"
                                                                    ? "blue.500"
                                                                    : "green.500"
                                                            }
                                                            mr={2}
                                                        />
                                                        {item?.content_title}
                                                    </Td>
                                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        {<Text>{moment.utc(item?.datetime_created).local().format("MMM DD,YYYY,HH:mm a")}</Text>}
                                                    </Td>
                                                    {/* <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        <Badge
                                                            colorScheme={!item?.vectorize ? "red" : "green"}
                                                        >
                                                            {item?.vectorize ? "YES" : "NO"}
                                                        </Badge>
                                                    </Td> */}
                                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        <Badge
                                                            colorScheme={!item.overwritten ? "red" : "green"}
                                                        >
                                                            {item?.overwritten ? "YES" : "NO"}
                                                        </Badge>
                                                    </Td>
                                                    {/* <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        <Badge colorScheme={!item.stored ? "red" : "green"}>
                                                            {item?.content_stored ? "YES" : "NO"}
                                                        </Badge>
                                                    </Td> */}
                                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        <Button size="sm" colorScheme="green">
                                                            View Original
                                                        </Button>
                                                    </Td>
                                                    <Td borderBottomWidth="1px" borderBottomColor="#d7d7d7">
                                                        <Button size="sm" colorScheme="green">
                                                            View Translation
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </Box>
                                <Flex position="absolute" bottom="0" right="0" w="full">
                                    <Pagination
                                        currentPage={pageId}
                                        setCurrentPage={setPageId}
                                        setRecordsPerPage={setRecordsPerPage}
                                        totalPages={totalPages}
                                        recordsPerPage={recordsPerPage}
                                    />
                                </Flex>
                            </Box>
                        ) : (
                            <Box w={"100%"} p={5}>
                                <Alert borderRadius="8px" status="error" variant="subtle">
                                    <Flex>
                                        <AlertIcon />
                                        <Flex direction="column">
                                            <AlertTitle mr="12px">Empty Resources List</AlertTitle>
                                            <AlertDescription>
                                                No Data Found!!
                                            </AlertDescription>
                                        </Flex>
                                    </Flex>
                                </Alert>
                            </Box>
                        )}
                    </Card>
                </Box>
            </Card>
        </Box>

    )
}

export default ContentList